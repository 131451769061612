import React, { useEffect, useState } from "react";
// import QRCode from "styled-qr-code";
import Header from "../../components/global/header";
import "./style.scss";
import Papa from "papaparse";
import { toast } from "react-toastify";
import SettingQR from "./SettingQr";
import { useDispatch, useSelector } from "react-redux";
import {
  useNavigate,
  useParams,
} from "react-router-dom";
import methodModel from "../../methods/methods";
import ApiClient from "../../methods/api/apiClient";
import { deactive_Plan } from "../../actions/activePlan";
import loader from "../../methods/loader";
import PatternColors from "../PatternColors";
import PHONE_template from "../BulkTemplates/PHONE_template.csv";
import EMAIL_template from "../BulkTemplates/EMAIL_template.csv";
import SMS_template from "../BulkTemplates/SMS_template.csv";

import TEXT_template from "../BulkTemplates/TEXT_template.csv";
import WHATSAPP_template from "../BulkTemplates/WHATSAPP_template.csv";
import URL_template from "../BulkTemplates/URL_template (2).csv";
import TemplatesDesign from "../Templates/TemplatesDesign";

import VCARD_template from "../BulkTemplates/VCARD_template.csv";
import Footer from "../../components/global/Footer";

const BulkQrCode = () => {
  const array = ["Test 1", "Test 2", "Test 3", "Test 4", "Test 5"];

  // const qrCode = new QRCodeStyling({
  //   width: 300,
  //   height: 300,
  //   type: "svg",
  //   data: "Bulk Qr Code",
  //   image: "",
  //   dotsOptions: {
  //     color: "#4267b2",
  //     type: "rounded"
  //   },
  //   backgroundOptions: {
  //     color: "#FFFFFF",
  //   },
  //   imageOptions: {
  //     crossOrigin: "anonymous",
  //     margin: 20
  //   }
  // });

  const [file, setFile] = useState([]);
  const [folder, setfolder] = useState([]);
  const user = useSelector((state) => state.user);

  const [eye, seteye] = useState(false);

  const token = localStorage.getItem("token");

  const activePlan = useSelector((state) => state.activePlan);
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [TabName, setTabName] = useState("upload");
  const code = methodModel.getPrams("code");
  const urlPath = window.location.pathname;
  const history = useNavigate();
  const [slugData, setslugData] = useState();

  const [form, setform] = useState({
    value: "",
    staticQrCode: true,
    logoimg: "",
    logosize: "4",
    qrOuterEye: "square",
    qrInnerEye: "square",
    qrBodyPattern: "square",
    qrOuterEyeColor: "#030202",
    qrInnerEyeColor: "#030202",
    qrBodyPatternColor: "#030202",
    backgroundColor: "#ffffff",
    qrOuterEyeColorGradient1: "",
    qrOuterEyeColorGradient2: "",
    qrOuterEyeColorGradientType: "linear",
    qrInnerEyeColorGradient1: "",
    qrInnerEyeColorGradient2: "",
    qrInnerEyeColorGradientType: "linear",
    qrBodyPatternGradientColor1: "",
    qrBodyPatternGradientColor2: "",
    qrBodyPatternGradientColorType: "linear",
    backgroundGradientColor1: "",
    backgroundGradientColor2: "",
    backgroundGradientColorType: "linear",
    errorCorrection: "Q",
    file: [],
  });
  useEffect(() => {
  }, [file]);
  const [qrCodeImage, setqrCodeImage] = useState();
  const [filter, setfilter] = useState({
    page: 1,
    count: 12,
    sortBy: "srNumber asc",
    status: "active",
  });
  const [radiobtn, setradiobtn] = useState({
    qrOuterEyeType: true,
    qrInnerEyeType: true,
    backgroundColorType: true,
    qrBodyPatternType: true,
    frameColor: true,
  });
  const [qrTypes, setqrTypes] = useState();
  const [common, setcommon] = useState({
    value1: "",
    value2: "",
    value3: "",
    value4: "",
    value5: "",
    value6: "",
    value7: "",
    value8: "",
  });
  const [selectSlug, setselectSlug] = useState();
  const [qrTypesViewMore, setqrTypesViewMore] = useState();
  const [searchViewMore, setsearchViewMore] = useState();
  const [ViewMoreSuggestions, setViewMoreSuggestions] = useState();
  const [selectFormat, setselectFormat] = useState("");
  const [downloadConfirm, setdownloadConfirm] = useState(false);
  // For Settings tab
  const [settingForm, setsettingForm] = useState({
    qrCodeName: "",
    folderName: "",
    folderId: "",
    flyer: "",
    printRun: "",
    campaignStart: "",
    campaignEnd: "",
    numberOfScansStatus: false,
    numberOfScans: "",
    dateRangeStatus: false,
    dateRangeStart: "",
    dateRangeEnd: "",
    expiryURL: "",
    externalTrackerFacebook: false,
    externalTrackerGoogle: false,
    passwordStatus: false,
    password: "",
    exactGPSLocation: false,
    limitScanLocation: false,
    longitude: "",
    latitude: "",
    radiusInKM: "",
  });
  // For vCard
  const [vcard, setvcard] = useState();
  //Add More
  const [smartURL, setsmartURL] = useState({
    ruleType: "location",
    defaultUrl: "",
  });
  const [location, setlocation] = useState([
    { country: "", region: "", city: "", url: "" },
  ]);
  const [numberOfScans, setnumberOfScans] = useState([{ scans: "", url: "" }]);
  const [time, settime] = useState([
    { timezone: "", startTime: "", endTime: "", url: "" },
  ]);
  const [language, setlanguage] = useState([{ language: "", url: "" }]);
  // For Frames
  const [frameForm, setframeForm] = useState({
    width: "250",
    height: "250",
    frame: "",
    qrFrameColor: "#030202",
    qrFrameColorGradient1: "",
    qrFrameColorGradient2: "",
    text: "SCAN ME",
    fontStyle: "",
    textColor: "#3c61e4",
  });

  useEffect(() => {
    // getSlugQrCodeDetail()
    // getQrCodeTypes();
    // getViewMoreQrCodeTypes();
    // getActivePlanDetail();
    if (slug == "multiple-apps" || slug == "smart-url" || slug == "file") {
      setform({ ...form, staticQrCode: false });
    }
    if (slug == "paynow") {
      setcommon({
        value1: true,
        value2: "",
        value3: "",
        value4: true,
        value5: "",
        value6: true,
        value7: "",
        value8: false,
        value9: "",
      });
    }
    if (slug == "sepa") {
      setcommon({
        value1: "",
        value2: "",
        value3: "",
        value4: "",
        value5: true,
        value6: "",
        value7: "",
        value8: "",
      });
    }
    // if (!activePlan?.planActive) {
    //   setform({ ...form, staticQrCode: true })
    // }
    let formData = JSON.parse(localStorage.getItem("QrCodeDesign"));
    if (formData) {
      setform(formData);
      localStorage.removeItem("QrCodeDesign");
    }
    let commonData = JSON.parse(localStorage.getItem("commonState"));
    if (commonData) {
      setcommon(commonData);
      localStorage.removeItem("commonState");
    }
    localStorage.removeItem("AfterLogin");
    localStorage.removeItem("DynamicQrCode");
  }, []);

  const getActivePlanDetail = () => {
    if (user?.id && token) {
      ApiClient.get(`active-subscription?user_id=${user?.id}`).then((res) => {
        if (res.success) {
          dispatch(active_Plan(res?.data));
        } else {
          dispatch(deactive_Plan());
        }
      });
    } else {
      dispatch(deactive_Plan());
    }
  };

  const getSlugQrCodeDetail = () => {
    if (slug || urlPath == "/urls") {
      ApiClient.get(
        `qrtype?id=${slug ? slug == "smart-url" ? "smart-url"  : slug.replaceAll("-", " ") : "urls"}`
      ).then((res) => {
        if (res.success) {
          setslugData(res?.data);
        }
      });
    }
  };

  const getQrCodeTypes = (p = {}) => {
    let fil = { ...filter, ...p };
    ApiClient.get(`qrtype/all`, fil).then((res) => {
      if (res.success) {
        setqrTypes(res.data);
      }
    });
  };

  const handleCount = () => {
    if (filter?.count == 12) {
      setfilter({ ...filter, count: 100 });
      getQrCodeTypes({ count: 100 });
    } else {
      setfilter({ ...filter, count: 12 });
      getQrCodeTypes({ count: 12 });
    }
  };

  const resetDesign = () => {
    setform({
      ...form,
      logoimg: "",
      logosize: "4",
      qrOuterEye: "square",
      qrInnerEye: "square",
      qrBodyPattern: "square",
      qrOuterEyeColor: "#030202",
      qrInnerEyeColor: "#030202",
      qrBodyPatternColor: "#030202",
      backgroundColor: "#FFFFFF",
      qrOuterEyeColorGradient1: "",
      qrOuterEyeColorGradient2: "",
      qrOuterEyeColorGradientType: "linear",
      qrInnerEyeColorGradient1: "",
      qrInnerEyeColorGradient2: "",
      qrInnerEyeColorGradientType: "linear",
      qrBodyPatternGradientColor1: "",
      qrBodyPatternGradientColor2: "",
      qrBodyPatternGradientColorType: "linear",
      backgroundGradientColor1: "",
      backgroundGradientColor2: "",
      backgroundGradientColorType: "linear",
      errorCorrection: "M",
    });
    setframeForm({
      width: "250",
      height: "250",
      frame: "",
      qrFrameColor: "#030202",
      qrFrameColorGradient1: "",
      qrFrameColorGradient2: "",
      text: "SCAN ME",
      fontStyle: "",
      textColor: "#3c61e4",
    });
    setradiobtn({
      qrOuterEyeType: true,
      qrInnerEyeType: true,
      backgroundColorType: true,
      qrBodyPatternType: true,
    });
  };

  const handleQrType = (slugType, type) => {
    if (
      slugType == "multiple apps" ||
      slugType == "smart-url" ||
      slugType == "file"
    ) {
      if (type == "direct") {
        window.open(`/${slugType.replace(" ", "-")}`);
      } else if (type == "viewMore") {
        setselectSlug(slugType);
      } else {
        handleQrCode(false, "No");
      }
    } else {
      if (
        slugType == slug?.replace("-", " ") ||
        urlPath.replace("/", "") == slugType
      ) {
        return;
      } else if (type == "viewMore") {
        setselectSlug(slugType);
      } else {
        window.open(`/${slugType.replace(" ", "-")}`);
      }
    }
  };

  const urlsQrCodeOnly = () => {
    if (
      qrTypesViewMore?.find((item) => item?.slug == slug.replaceAll("-", " "))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const headerText = () => {
    // if (slug == 'url' || urlPath == '/urls') {
    //   return 'Free QR Code Generator with Logo and Brand Colors'
    // }
    // else if (slug == 'whatsapp') {
    //   return 'WhatsApp QR Code Generator'
    // }
    // else if (slug == 'google-forms') {
    //   return 'Google Forms QR Code Generator'
    // }
    // else if (slug == 'sheets') {
    //   return 'Google Sheets QR Code Generator'
    // }
    // else if (slug == 'docs') {
    //   return 'Google Docs QR Code Generator'
    // }
    // else if (slug == 'wifi') {
    //   return 'Free WiFi QR Code Generator'
    // }
    // else if (slug == 'events') {
    //   return 'Calendar QR Code Generator'
    // }
    // else if (slug == 'call') {
    //   return 'Phone Number QR Code Generator'
    // }
    // else if (slug == 'sms') {
    //   return 'SMS QR Code Generator'
    // }
    // else if (slug == 'location' || slug == 'maps') {
    //   return 'Location QR Code Generator'
    // }
    // else if (slug == 'vcard') {
    //   return 'vCard QR Code Generator'
    // }
    // else if (slug == 'paynow') {
    //   return 'PayNow QR Code Generator'
    // }
    // else if (slug == 'sepa') {
    //   return 'SEPA QR Code Generator'
    // }
    // else if (urlsQrCodeOnly()) {
    //   return <span className="text-capitalize">{slug.replaceAll('-', ' ')} QR Code Generator</span>
    // }
    // else {
    //   return ''
    // }
    return " ";
  };

  // View More Search Functions
  const getViewMoreQrCodeTypes = (p = {}) => {
    let payload = { ...p, status: "active" };
    ApiClient.get(`qrtype/all`, payload).then((res) => {
      if (res.success) {
        setqrTypesViewMore(res.data);
      }
    });
  };

  const handleViewMoreSearch = (e) => {
    setsearchViewMore(e);
    ApiClient.get(`qrtype/all?search=${e || "zzzz"}&status=active`).then(
      (res) => {
        if (res.success) {
          setViewMoreSuggestions(res?.data);
        }
      }
    );
    if (!e) getViewMoreQrCodeTypes({});
  };

  const selectViewMoreSearch = (type) => {
    getViewMoreQrCodeTypes({ search: type });
    setViewMoreSuggestions([]);
  };

  const ViewMoreModal = () => {
    setsearchViewMore("");
    setViewMoreSuggestions([]);
    getViewMoreQrCodeTypes({});
    setselectSlug("");
  };

  const staticQrCode = () => {
    if (
      slug == "sepa" ||
      slug == "paynow" ||
      slug == "wifi" ||
      slug == "call" ||
      slug == "text" ||
      slug == "sms" ||
      slug == "email" ||
      slug == "location" ||
      slug == "maps" ||
      slug == "vcard"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleQrCode = (check, modal) => {
    if (!check) {
      if (modal == "No") {
        if (activePlan?.planActive) {
          setform({ ...form, staticQrCode: check });
        } else {
          if (user?.id) {
            document.getElementById("OpenDynamicQrCode").click();
          } else {
            document.getElementById("OpenSignUpModal").click();
          }
        }
      } else {
        setform({ ...form, staticQrCode: check });
        localStorage.setItem(
          "QrCodeDesign",
          JSON.stringify({ ...form, staticQrCode: check })
        );
        localStorage.setItem("commonState", JSON.stringify(common));
        localStorage.setItem("DynamicQrCode", urlPath);
        history(`/pricing`);
        document.getElementById("ClodeDynamicQrCode").click();
      }
    } else {
      setform({ ...form, staticQrCode: check });
    }
  };
  const getFolders = () => {
    loader(true);
    ApiClient.get(`folder/all?user_id=${user?.id}`).then((res) => {
      if (res.success) {
      
        setfolder(res?.data?.data);
      }
      loader(false);
    });
  };

  useEffect(() => {
    if (user?.id) {
      getFolders();
    }
  }, [user]);

  const Handlenexttab = (e) => {
    e.preventDefault();
    if (TabName == "upload") {
      setTabName("design");
      document.getElementById("pills-user_2-tab").click();
    } else if (TabName == "design") {
      setTabName("setting");
      document.getElementById("pills-user_3-tab").click();
    } else {
      setdownloadConfirm(true);
    }
  };

  const Preveiostab = (e) => {
    e.preventDefault();
    if (TabName == "setting") {
      setTabName("design");
      document.getElementById("pills-user_2-tab").click();
    } else {
      setTabName("upload");
      document.getElementById("pills-user_1-tab").click();
    }
  };

  const handleFolderSelect = (e) => {
    let folderDetail = folder.find((item) => item?.name == e);
    setsettingForm({
      ...settingForm,
      folderName: e,
      folderId: folderDetail?._id,
    });
  };

  const handleCreateFolder = (e) => {
    e.preventDefault();
    let payload = {
      name: settingForm?.folderName,
    };
    loader(true);
    ApiClient.post(`folder`, payload).then((res) => {
      if (res.success) {
        // HandleNewfolderclosemodal()
        toast.success(res.message);
        getFolders();
        setsettingForm({
          ...settingForm,
          folderId: res?.data?.id,
          folderName: "",
        });
      }
      loader(false);
    });
    document.getElementById("CloseFolderModal").click();
  };

  const handleFileUpload = (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        // Check if any data field is empty
        const isEmptyField = results.data.some((item) =>
          Object.values(item).some((value) => !value.trim())
        );

        // Check if QR_Type has inconsistent values
        const qrTypes = results.data.map((item) => item.QR_Code);
        const isAllDynamic = qrTypes.every((type) => type === "dynamic");
        const isAllStatic = qrTypes.every((type) => type === "static");
        const hasMixedValues = !isAllDynamic && !isAllStatic;

        if (isEmptyField) {
          toast.error("Some fields are empty.");
        } else if (hasMixedValues) {
          toast.error("QR_Type should have all dynamic or all static values.");
        } else {
          setFile(results.data);
          toast.success("Your file is processed");
          // document.getElementById('pills-user_2-tab').click();
        }
      },
    });
  };
  useEffect(() => {
    // document.getElementById('BulkQrCode').innerHTML = ''
    // qrCode.append(document.getElementById("BulkQrCode"));
  }, []);

  return (
    <>
      <Header />
      {/* <div id={`BulkQrCode`}></div> */}
      {/* {array.map((item, index) => (
        <div key={index}>
          <QRCode
            value={item}
            size={150}
            level="H"
            bgColor="#ffffff"
            fgColor="#000000"
            shape="circle"
          />          
          <p>{item}</p>
        </div>
      ))} */}
      <div className="bgback back-gradient py-4">
        <div className="">
          {/* section 1 */}
          <div className="text-detail my-3">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-8">
                  <h4 className="fw-bold">
                    {" "}
                    Free QR Code Generator with Logo and Brand Colors
                  </h4>
                  <h6 className="text-primary fw-normal">
                    {" "}
                    No ads, no hidden fees. Track scans of your QR Codes.
                  </h6>
                </div>
                <div className="col-md-4 text-end">
                  <div className="img_lock">
                  <a href="#" role="button">
                    <img
                      src="./assets/img/gdpr-lock.png"
                      className=" gdpr-btn mb-2"
                      alt="..."
                    />
                  </a>
                  <a href="#" role="button">
                    <img
                      src="./assets/img/gdpr-check.png"
                      className=" gdpr-btn mb-2 "
                      alt="..."
                    />
                  </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* section2 */}
          <div className="data-box">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 col-lg-8 mb-3">
                  <div className="bg-white p-3 border border-1 rounded-3 set-bg-box">
                    {/* <ul
                      className="nav nav-pills data-pills mb-4"
                      id="pills-tab"
                      role="tablist"
                    >
                        

                      
                      <li className="nav-item" role="presentation">
                        <button
                          
                          className="nav-link"
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="false"
                          
                        >
                          Download Template{" "}
                          <img
                            src="./assets/img/download.png"
                            className="data-img"
                            alt=""
                          />
                        </button>
                      </li>
                    </ul> */}
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                        tabindex="0"
                      >
                        <div className="d-flex gap-2 para-text my-3 ">
                          <img
                            src="./assets/img/lightbulb_.png"
                            className="light-blub"
                            alt=""
                          />
                          <p className="fw-normal mb-0">
                            Upload the CSV file for each QR Code on a new row.
                            {/* To create QR Codes with multiple data fields per row
                            (e.g. name, email, mobile for vCards),{" "}
                            <a href="#" className="fw-bold">
                              Log in
                            </a>{" "}
                            to upload your excel template with multiple columns. */}
                          </p>
                        </div>
                        <div
                          className="d-flex flex-column text-center  upload-text"
                          // onClick={() => {
                          //   if (file?.length == 0) {
                          //     document.getElementById("BulkUploader2").click();
                          //   }
                          // }}
                        >
                          <input
                            type="file"
                            name="BulkFileReader"
                            accept=".csv"
                            id="BulkUploader2"
                            onChange={(e) => handleFileUpload(e)}
                            className="d-none"
                          />
                          {file?.length == 0 ? (
                            <div
                              class="dot_div"
                              onClick={() => {
                                document
                                  .getElementById("OpenSignUpModal")
                                  .click();
                              }}
                            >
                              <div>
                                <p>Drag and drop to upload or</p>
                                <h5>
                                  Browse{" "}
                                  <span class="material-icons ms-2">
                                    drive_folder_upload
                                  </span>
                                </h5>
                              </div>
                            </div>
                          ) : (
                            <div
                              // style={{ flexDirection: "column" }}
                              className="d-flex dot_div justify-content-center align-items-center"
                            >
                              <img
                                style={{ width: "120px", textAlign: "center" }}
                                src="https://www.creativefabrica.com/wp-content/uploads/2020/12/01/Line-Style-Selected-File-Icon-Office-Graphics-6934835-1.jpg"
                                alt=""
                              />
                              <i
                                style={{
                                  position: "absolute",
                                  marginLeft: "80px",
                                }}
                                onClick={() => {
                                  setFile([]);
                                  setform({});
                                }}
                                class="fa fa-times "
                                aria-hidden="true"
                              ></i>
                              {/* <p>{form?.file}</p> */}
                            </div>
                          )}
                        </div>
                        <div className="row gap-md-0 gap-2">
                          <div className="col-md-5 pr-md-1">
                            <label
                              className="form-check-label fw-bold w-100"
                              for="flexRadioDefault1"
                            >
                              <div className="activeurl rounded-3 height_box p-3">
                                <div className="form-check ">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault1"
                                  />
                                  Static QR Code
                                  <ul className="list-group list-gp-btn">
                                    <li className="list-group-item fw-normal li-green">
                                      100% free forever
                                    </li>
                                    <li className="list-group-item fw-normal li-close">
                                      Cannot edit data once created
                                    </li>
                                    <li className="list-group-item fw-normal li-close">
                                      {" "}
                                      Cannot track scans
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </label>
                          </div>
                          <div className="col-md-7">
                            <label
                              className="form-check-label fw-bold w-100"
                              for="flexRadioDefault1"
                            >
                              <div className="urlbtn rounded-3 height_box p-3">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault1"
                                  />
                                  Dynamic QR Code
                                  <ul className="list-group list-gp-btn">
                                    <li className="list-group-item fw-normal li-green">
                                      Edit data or change QR type even after its
                                      printed
                                    </li>
                                    <li className="list-group-item fw-normal li-green">
                                      Track analytics; number of scans, time,
                                      location, device and more
                                    </li>
                                    <li className="list-group-item fw-normal li-green">
                                      {" "}
                                      Redirect users based on your rules
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>

                        {/* 
                        <div className="d-flex gap-2 para-text">
                          <img
                            src="./assets/img/lightbulb_.png"
                            className="light-blub"
                            alt=""
                          />
                          <p className="fw-normal ">
                            Enter the data for each QR Code on a new row. To
                            create QR Codes with multiple data fields per row
                            (e.g. name, email, mobile for vCards),{" "}
                            <a href="#" className="fw-bold">
                              Log in
                            </a>{" "}
                            to upload your excel template with multiple
                            columns. 
                          </p>
                        </div>
                        <ul className="ul-link">
                          <li>
                            <a
                              href={EMAIL_template}
                              download="Example-EMAIL-document"
                            >
                              <span className="bg-dark text-white  span-no">
                                1
                              </span>{" "}
                              Email Template
                            </a>
                          </li>
                          <li>
                            <a
                              href={WHATSAPP_template}
                              download="Example-Whatsapp-document"
                            >
                              <span className="bg-dark text-white  span-no">
                                2
                              </span>{" "}
                              Whatsapp Template
                            </a>
                          </li>
                          <li>
                            <a
                              href={URL_template}
                              download="Example-URL-document"
                            >
                              <span className="bg-dark text-white  span-no">
                                3
                              </span>{" "}
                              URL Template
                            </a>
                          </li>
                          <li>
                            <a
                              href={PHONE_template}
                              download="Example-PHONE-document"
                            >
                              <span className="bg-dark text-white  span-no">
                                4
                              </span>{" "}
                              Phone Template
                            </a>
                          </li>
                          <li>
                            <a
                              href={VCARD_template}
                              download="Example-VCARD-document"
                            >
                              <span className="bg-dark text-white  span-no">
                                5
                              </span>{" "}
                              Vcard Template
                            </a>
                          </li>
                          <li>
                            <a
                              href={TEXT_template}
                              download="Example-TEXT-document"
                            >
                              <span className="bg-dark text-white  span-no">
                                6
                              </span>{" "}
                              Text Template
                            </a>
                          </li>
                          <li>
                            <a
                              href={SMS_template}
                              download="Example-SMS-document"
                            >
                              <span className="bg-dark text-white  span-no">
                                7
                              </span>{" "}
                              SMS Template
                            </a>
                          </li>
                          </ul> */}
                        {/*  <li>
                            <a href="#">
                              <span className="bg-dark text-white  span-no">
                                2
                              </span>{" "}
                              https://websiteurl.com
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <span className="bg-dark text-white  span-no">
                                3
                              </span>{" "}
                              https://websiteurl.com
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <span className="bg-dark text-white  span-no">
                                4
                              </span>{" "}
                              https://websiteurl.com
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <span className="bg-dark text-white  span-no">
                                5
                              </span>{" "}
                              https://websiteurl.com
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <span className="bg-dark text-white  span-no">
                                6
                              </span>{" "}
                              https://websiteurl.com
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <span className="bg-dark text-white  span-no">
                                7
                              </span>{" "}
                              https://websiteurl.com
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <span className="bg-dark text-white  span-no">
                                8
                              </span>{" "}
                              https://websiteurl.com
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <span className="bg-dark text-white  span-no">
                                9
                              </span>{" "}
                              https://websiteurl.com
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <span className="bg-dark text-white  span-no">
                                10
                              </span>{" "}
                              https://websiteurl.com
                            </a>
                          </li> */}

                        {/* <div className="row gap-md-0 gap-2">
                          <div className="col-md-5 pr-md-1">
                            <label
                              className="form-check-label fw-bold w-100"
                              for="flexRadioDefault1"
                            >
                              <div className="activeurl rounded-3 height_box p-3">
                                <div className="form-check ">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault1"
                                  />
                                  Static QR Code
                                  <ul className="list-group list-gp-btn">
                                    <li className="list-group-item fw-normal li-green">
                                      100% free forever
                                    </li>
                                    <li className="list-group-item fw-normal li-close">
                                      Cannot edit data once created
                                    </li>
                                    <li className="list-group-item fw-normal li-close">
                                      {" "}
                                      Cannot track scans
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </label>
                          </div>
                          <div className="col-md-7">
                            <label
                              className="form-check-label fw-bold w-100"
                              for="flexRadioDefault1"
                            >
                              <div className="urlbtn rounded-3 height_box p-3">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault1"
                                  />
                                  Dynamic QR Code
                                  <ul className="list-group list-gp-btn">
                                    <li className="list-group-item fw-normal li-green">
                                      Edit data or change QR type even after its
                                      printed
                                    </li>
                                    <li className="list-group-item fw-normal li-green">
                                      Track analytics; number of scans, time,
                                      location, device and more
                                    </li>
                                    <li className="list-group-item fw-normal li-green">
                                      {" "}
                                      Redirect users based on your rules
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div> */}
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                        tabindex="0"
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-4 mb-3">
                  <div className="bg-white border p-3 border-1 rounded-3">
                    <h6 className="fw-bold text-dark">
                      Design QR Code{" "}
                      <span className="text-primary fw-bold float-end">
                        Reset-Design
                      </span>
                    </h6>
                    <SettingQR
                      qrTypesViewMore={qrTypesViewMore}
                      frameForm={frameForm}
                      settingForm={settingForm}
                      smartURL={smartURL}
                      location={location}
                      numberOfScans={numberOfScans}
                      time={time}
                      language={language}
                      qrCodeImage={qrCodeImage}
                      setqrCodeImage={setqrCodeImage}
                      form={{ ...form, file: file }}
                      setform={setform}
                      common={common}
                      urlPath={urlPath}
                      selectFormat={selectFormat}
                      downloadConfirm={downloadConfirm}
                      setdownloadConfirm={setdownloadConfirm}
                      setselectFormat={setselectFormat}
                      slug={slug}
                      vcard={vcard}
                    />
                    {urlPath == "/bulk-qr-code" ? null : (
                      <>
                        <div className="color-code mb-3">
                          <label for="" className="my-3 fw-bold">
                            QR Code Color
                          </label>
                          <div className="code-btn d-flex gap-3 align-items-center">
                            {" "}
                            <h6 className="color-bx mb-0"></h6> #1961E4{" "}
                          </div>
                        </div>
                      </>
                    )}
                    <div className="color-code mb-0">
                      {urlPath == "/bulk-qr-code" ? null : (
                        <>
                          <label for="" className="my-3 fw-bold">
                            Background Color
                          </label>
                          <div className="form-check d-flex  gap-5 pb-2 px-0">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault1"
                              />
                              <label
                                className="form-check-label"
                                for="flexRadioDefault1"
                              >
                                Solid Color
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault2"
                                checked
                              />
                              <label
                                className="form-check-label"
                                for="flexRadioDefault2"
                              >
                                Transparent
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="info_detail mb-3">
                        {urlPath == "/bulk-qr-code" ? null : (
                          <>
                            <label for="" className="my-3 fw-bold">
                              Error correction{" "}
                              <img
                                src="./assets/img/info_outline.png"
                                className="img-fluid"
                                alt=""
                              />
                            </label>
                            <select
                              className="form-select "
                              aria-label="Default select example"
                            >
                              <option selected>Level H (High) - 30%</option>
                              <option value="1">Level H (High) - 30%</option>
                              <option value="2">Level H (High) - 30%</option>
                              <option value="3">Level H (High) - 30%</option>
                            </select>
                          </>
                        )}
                        <PatternColors
                          setform={setform}
                          form={form}
                          setradiobtn={setradiobtn}
                          radiobtn={radiobtn}
                        />
                        <div className="d-flex gap-2 para-text py-4">
                          <img
                            src="./assets/img/lightbulb_.png"
                            className="light-blub"
                            alt=""
                          />
                          <p className="fw-normal mb-0">
                            <a href="#" className="fw-bold">
                              Log in
                            </a>{" "}
                            for more design options such as adding your logo or
                            custom labels
                          </p>
                        </div>
                        <button
                          onClick={() => {
                            document.getElementById("OpenSignUpModal").click();
                          }}
                          className="btn btn-primary w-100 fw-bold"
                          // data-bs-toggle="modal"
                          // data-bs-target="#exampleModal"
                          // data-bs-whatever="@getbootstrap"
                        >
                          Generate Bulk QR
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- modal page  --> */}
        <div
          className="modal fade  modal-set"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content p-3 ">
              <div className="modal-body data-body p-0 mb-3">
                <form>
                  <div className="one">
                    <div className="row">
                      <div className="col-4 fw-bold">File</div>
                      <div className="col-4 fw-bold">Data</div>
                      <div className="col-4 fw-bold">QR Code</div>
                    </div>
                  </div>
                  {array &&
                    array.map((item, index) => {
                      return (
                        <div className="one">
                          <div className="row align-items-center">
                            <div className="col-4">{index + 1}</div>
                            <div className="col-4">{item?.url}</div>
                            <div className="col-4">Qr Code</div>
                          </div>
                        </div>
                      );
                    })}
                  {/* <div className="one">
                  <div className="row align-items-center">
                    <div className="col-2">2</div>
                    <div className="col-8">https://websiteurl.com</div>
                    <div className="col-2"> <img src="./assets/img/or-code.png" className="set-img" alt="" /></div>
                  </div>
                </div>
                <div className="one border-bottom-0">
                  <div className="row align-items-center">
                    <div className="col-2">3</div>
                    <div className="col-8">https://websiteurl.com</div>
                    <div className="col-2"> <img src="./assets/img/or-code.png" className="set-img" alt="" /></div>
                  </div>
                </div> */}
                </form>
              </div>
              <div className="btn_button-bx">
                <div className="row">
                  <div className="col-6">
                    <button
                      className="btn btn-outline-secondary w-100"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="btn btn-primary w-100"
                      data-bs-dismiss="modal"
                    >
                      Download Zip{" "}
                      <img
                        src="./assets/img/white-dwonload.png"
                        className="img-fluid ms-2"
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BulkQrCode;
